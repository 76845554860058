import { LayoutsHome } from "../layouts";

import { CashPage } from "../modules/modules.accounting/pages.accounting/CashPage";
import { BankPage } from "../modules/modules.accounting/pages.accounting/BankPage";
import { AccountPayPage } from "../modules/modules.accounting/pages.accounting/AccountPayPage";
import { AccountReceivePage } from "../modules/modules.accounting/pages.accounting/AccountReceivePage";
import { CollectionSheetPage } from "../modules/modules.accounting/pages.accounting/CollectionSheetPage";

const routesACCOUNTING = [
  {
    path: "/accounting/cash_control",
    layout: LayoutsHome,
    component: CashPage,
  },
  {
    path: "/accounting/banks",
    layout: LayoutsHome,
    component: BankPage,
  },
  {
    path: "/accounting/accountPay",
    layout: LayoutsHome,
    component: AccountPayPage,
  },
  {
    path: "/accounting/accountReceive",
    layout: LayoutsHome,
    component: AccountReceivePage,
  },
  {
    path: "/accounting/CollectionSheetPage",
    layout: LayoutsHome,
    component: CollectionSheetPage,
  },
];
export default routesACCOUNTING;
