import React, { useState, useEffect, useContext, createContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { sendAnnulmentOperation } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
import { ContextAuth } from "../../../contexts/ContextAuth";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { updateRemainingQuantity } from "../../../apis/apis.logistics/apisProduct";

export function InventoryList(props) {
    const { auth } = useContext(ContextAuth);
    const { setInventories, inventories } = useContext(GlobalContext);
    const warmStyle = { backgroundColor: "#673ab7", color: "#ffffff" };
    const infoStyle = { backgroundColor: "#3f51b5", color: "#ffffff" };
    const successStyle = { backgroundColor: "#2196f3", color: "#ffffff" };
    const dangerStyle = { backgroundColor: "#03a9f4", color: "#ffffff" };

    const [editingIndex, setEditingIndex] = useState(null); // Índice del producto en edición
    const [newPrice, setNewPrice] = useState(""); // Nuevo precio temporal

    async function handleClickBtnAnnular(item) {
        if (
            window.confirm("¿Estás seguro de que deseas anular esta operación?")
        ) {
            console.log("confirm");

            await sendAnnulment({
                operationID: item.operationID,
                userID: auth.data.user_id,
            }).then((r) => {
                if (r.status == 201) {
                    toast.success("Operacion anulada con exito!");
                } else {
                    toast.error("Ocurrio algun error");
                }
            });
        } else {
            console.log("cancel");
            console.log(item);
        }
    }

    const sendAnnulment = async (data) => {
        try {
            const response = await sendAnnulmentOperation(data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    // Manejar el doble clic para activar la edición
    const handleDoubleClick = (index) => {
        setEditingIndex(index);
        setNewPrice(inventories[index].remainingQuantity); // Establece el precio actual en el input
    };

    // Manejar el cambio de valor en el input
    const handleChange = (e) => {
        setNewPrice(e.target.value);
    };

    const saveRemainingQuantity = async (data) => {
        try {
            const response = await updateRemainingQuantity(data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    // Guardar el nuevo precio al presionar "Enter"
    const handleKeyDown = (e, index, pk) => {
        if (e.key === "Enter") {
            const updatedInventories = inventories.map((inventory, i) =>
                i === index
                    ? { ...inventory, remainingQuantity: Number(newPrice) }
                    : inventory
            );
            saveRemainingQuantity({
                inventoryID: Number(pk),
                newRemainingQuantity: Number(newPrice),
            });
            setInventories(updatedInventories);
            setEditingIndex(null); // Salir del modo de edición
        }
    };
    // Manejar la salida del modo de edición al perder el foco
    const handleBlur = () => {
        setEditingIndex(null);
    };
    return (
        <>
            <button
                className="btn btn-outline-warning my-3 float-right d-none"
                data-toggle="modal"
                data-target="#modal-register-operation"
            >
                Registrar Nuevo Operacion
            </button>

            <button
                className="btn btn-light my-3 float-right font-weight-light"
                data-toggle="modal"
                data-target="#modal-register-sale-operation"
            >
                Registrar Venta
            </button>

            <div className="row font-weight-light">
                <div className="col-auto">
                    {props.selectedInventory.warehouseName}
                </div>
                <div className="col-auto">/</div>
                <div className="col">{props.selectedInventory.productName}</div>
            </div>

            <table
                className="table table-sm table-bordered text-uppercase text-center"
                id="table-to-xls"
            >
                <thead>
                    <tr>
                        <td
                            colSpan={14}
                            className="align-middle text-center text-uppercase"
                            style={warmStyle}
                        >
                            {props.selectedInventory.productName}
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={5}
                            className="align-middle text-center text-uppercase"
                            style={warmStyle}
                        >
                            Descripcion
                        </td>
                        <td
                            colSpan={3}
                            className="align-middle text-center text-uppercase "
                            style={infoStyle}
                        >
                            Entrada
                        </td>
                        <td
                            colSpan={3}
                            className="align-middle text-center text-uppercase "
                            style={successStyle}
                        >
                            Salida
                        </td>
                        <td
                            colSpan={3}
                            className="align-middle text-center text-uppercase "
                            style={dangerStyle}
                        >
                            Saldo
                        </td>
                        {/* <td
              rowSpan={2}
              className="align-middle text-center text-uppercase "
              style={dangerStyle}
            >
              Acciones
            </td> */}
                    </tr>
                    <tr>
                        <td className="" style={warmStyle}>
                            id
                        </td>
                        <td className="" style={warmStyle}>
                            fecha
                        </td>
                        <td className="" style={warmStyle}>
                            usuario
                        </td>
                        <td className="" style={warmStyle}>
                            tipo de operacion
                        </td>
                        <td className="" style={warmStyle}>
                            Comprobante
                        </td>
                        <td className="" style={infoStyle}>
                            cant
                        </td>
                        <td className="" style={infoStyle}>
                            precio unitario
                        </td>
                        <td className="" style={infoStyle}>
                            precio total
                        </td>

                        <td className="" style={successStyle}>
                            cant
                        </td>
                        <td className="" style={successStyle}>
                            precio unitario
                        </td>
                        <td className="" style={successStyle}>
                            precio total
                        </td>

                        <td className="" style={dangerStyle} colSpan={3}>
                            cant restante
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {inventories.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                value={item.operationID}
                                className={
                                    item.operationStatus == "03"
                                        ? "bg-danger"
                                        : item.operationType == "01"
                                        ? "bg-warning"
                                        : item.operationType == "02"
                                        ? "bg-primary"
                                        : "text-dark"
                                }
                            >
                                <td className="" scope="row">
                                    {item.operationID}
                                </td>
                                <td className="">{item.operationDate}</td>
                                <td className="">{item.operationUser}</td>
                                <td className="">
                                    {item.operationType == "04" &&
                                    item.operationAction == "S"
                                        ? "TRANSBORDO - ABASTECIMIENTO"
                                        : item.operationTypeDisplay}
                                    : {item.operationDocumentNumber}
                                </td>

                                <td className="">
                                    {item.operationType == "04" &&
                                    item.operationAction == "S"
                                        ? ""
                                        : item.operationType == "02"
                                        ? item.operationClient
                                        : item.operationType == "01"
                                        ? item.operationDocumentNumber
                                        : item.operationDocumentNumber}
                                </td>

                                <td className="">
                                    {item.operationAction == "E"
                                        ? item.quantity + ""
                                        : ""}
                                </td>
                                <td className="">
                                    {item.operationAction == "E"
                                        ? "S/" + item.priceUnit
                                        : ""}
                                </td>
                                <td className="">
                                    {item.operationAction == "E"
                                        ? "S/" + item.priceTotal
                                        : ""}
                                </td>

                                <td className="">
                                    {item.operationAction == "S"
                                        ? item.quantity + ""
                                        : ""}
                                </td>
                                <td className="">
                                    {item.operationAction == "S"
                                        ? "S/" + item.priceUnit
                                        : ""}
                                </td>
                                <td className="">
                                    {item.operationAction == "S"
                                        ? "S/" + item.priceTotal
                                        : ""}
                                </td>

                                <td className="" colSpan={3}>
                                    {/* {item.remainingQuantity + ""} */}

                                    {editingIndex === index ? (
                                        <input
                                            type="text"
                                            value={newPrice}
                                            className="form-control mx-1"
                                            onChange={handleChange}
                                            onKeyDown={(e) =>
                                                handleKeyDown(
                                                    e,
                                                    index,
                                                    item.operationDetailID
                                                )
                                            }
                                            onBlur={handleBlur}
                                            onFocus={(e) => e.target.select()}
                                            autoFocus
                                        />
                                    ) : (
                                        <span
                                            onDoubleClick={() =>
                                                handleDoubleClick(index)
                                            }
                                        >
                                            {item.remainingQuantity}
                                        </span>
                                    )}
                                </td>

                                {/* <td className="">
                  <button
                    type="button"
                    className="btn btn-light font-weight-light d-none"
                    onClick={() => handleClickBtnAnnular(item)}
                    disabled={true}
                  >
                    Anular
                  </button>
                </td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-outline-secondary mt-3 ml-1"
                table="table-to-xls"
                filename="report_of_inventory"
                sheet="report"
                buttonText="Descargar como XLS"
            />
        </>
    );
}
