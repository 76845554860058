import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DetailList } from "./DetailList";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getCurrentDate } from "../../../configs/helpers";
import {
    resendInvoice,
    sendCancelSaleData,
} from "../../../apis/apis.dispatches/apisDispatch";

export function DistributionList({ orders, filename, questionState }) {
    const [items, setItems] = useState([]);

    const operationValues = Object.values(orders);

    const fetchResendInvoice = async (data) => {
        try {
            const response = await resendInvoice(data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const fetchCancelSale = async (data) => {
        try {
            const response = await sendCancelSaleData(data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    let tbodies;

    if (questionState.mode == "01") {
        tbodies = operationValues.map((item, index) => {
            const payValues = Object.values(item.operationDetailDict);
            let payRows = payValues.map((itempay, i) => {
                const quotationRegisterDate =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02"
                                ? item.quotationRegisterDate
                                : item.registerDate}
                        </td>
                    ) : null;
                const quotationRegisterTime =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02"
                                ? item.quotationRegisterTime
                                : item.registerTime}
                        </td>
                    ) : null;
                const quotationUserName =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02"
                                ? item.quotationUserName
                                : item.userName}
                        </td>
                    ) : null;
                const registerDate =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02"
                                ? item.registerDate
                                : ""}
                        </td>
                    ) : null;
                const registerTime =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02"
                                ? item.registerTime
                                : ""}
                        </td>
                    ) : null;
                const userName =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationType == "02" ? item.userName : ""}
                        </td>
                    ) : null;
                const clientObservation =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.clientObservation}
                        </td>
                    ) : null;
                const clientName =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.clientName}
                        </td>
                    ) : null;
                const clientPhysicalDistributionDisplay =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.clientPhysicalDistributionDisplay}
                        </td>
                    ) : null;
                const clientVisitDayDisplay =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.clientVisitDayDisplay}
                        </td>
                    ) : null;
                const clientAddress =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.clientAddress}
                        </td>
                    ) : null;
                const receiptSerial =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.receiptSerial}
                        </td>
                    ) : null;
                const receiptNumber =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.receiptNumber}
                        </td>
                    ) : null;
                const total =
                    i === 0 ? (
                        <td
                            className="align-middle text-right font-weight-light"
                            rowSpan={payValues.length}
                        >
                            S/ {Number(item.total).toFixed(2)}
                        </td>
                    ) : null;
                const quotationTotal =
                    i === 0 ? (
                        <td
                            className="align-middle text-right font-weight-light"
                            rowSpan={payValues.length}
                        >
                            S/ {Number(item.quotationTotal).toFixed(2)}
                        </td>
                    ) : null;
                const operationStatus =
                    i === 0 ? (
                        <td
                            className="align-middle text-center font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.operationStatus != 200 ? (
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    value={item.operationID}
                                    onClick={(e) => {
                                        console.log(item.operationID);
                                        if (
                                            window.confirm(
                                                "¿Esta usted seguro de anular la venta?"
                                            )
                                        ) {
                                            fetchCancelSale({
                                                operationID: item.operationID,
                                                userID: item.userID,
                                            }).then((res) => {
                                                toast.info(res.message);
                                                document
                                                    .getElementById(
                                                        "btn-filter"
                                                    )
                                                    .click();
                                            });
                                        }
                                    }}
                                >
                                    <i className="fas fa-ban"></i>
                                </button>
                            ) : (
                                item.operationStatus
                            )}
                        </td>
                    ) : null;

                const httpStatusCode =
                    i === 0 ? (
                        <td
                            className="align-middle text-right font-weight-light"
                            rowSpan={payValues.length}
                        >
                            {item.httpStatusCode !== 200 &&
                            item.receiptSerial !== null ? (
                                <>
                                    {item.httpStatusCode === 400 ? (
                                        <div
                                            className="alert alert-default-danger"
                                            role="alert"
                                        >
                                            MENSAJE: {item.errorMessage}
                                        </div>
                                    ) : null}
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        value={item.operationID}
                                        onClick={(e) => {
                                            console.log(item.operationID);

                                            fetchResendInvoice({
                                                operationID: item.operationID,
                                            }).then((res) => {
                                                toast.info(res.message);
                                                document
                                                    .getElementById(
                                                        "btn-filter"
                                                    )
                                                    .click();
                                            });
                                        }}
                                    >
                                        REENVIAR
                                    </button>
                                </>
                            ) : (
                                item.httpStatusCode
                            )}
                        </td>
                    ) : null;
                // const errorCode = i === 0 ? (<td className="align-middle text-right font-weight-light" rowSpan={payValues.length}>{item.errorCode}</td>) : null;
                // const errorMessage = i === 0 ? (<td className="align-middle text-right font-weight-light" rowSpan={payValues.length}>{item.errorMessage}</td>) : null;
                return (
                    <tr key={i}>
                        {quotationRegisterDate}
                        {quotationRegisterTime}
                        {quotationUserName}
                        {registerDate}
                        {registerTime}
                        {userName}
                        {clientObservation}
                        {clientName}
                        {clientPhysicalDistributionDisplay}
                        {clientVisitDayDisplay}
                        {clientAddress}

                        <td className="align-middle text-left font-weight-light">
                            {itempay.productSku}
                        </td>
                        <td className="align-middle text-left font-weight-light">
                            {itempay.productName}
                        </td>
                        <td className="align-middle text-center font-weight-light">
                            {itempay.unitName}
                        </td>
                        <td className="align-middle text-center font-weight-light">
                            {itempay.quantity}
                        </td>
                        <td className="align-middle text-center font-weight-light">
                            {Number(itempay.price).toFixed(2)}
                        </td>
                        <td className="align-middle text-center font-weight-light">
                            {Number(itempay.subtotal).toFixed(2)}
                        </td>
                        {receiptSerial}
                        {receiptNumber}
                        {total}
                        {quotationTotal}

                        {/*errorCode*/}
                        {/*errorMessage*/}
                        {operationStatus}
                        {httpStatusCode}
                    </tr>
                );
            });

            return payRows;
        });
    } else {
        tbodies = operationValues.map((item, index) => {
            const quotationRegisterDate = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationRegisterDate
                        : item.registerDate}
                </td>
            );
            const quotationRegisterTime = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationRegisterTime
                        : item.registerTime}
                </td>
            );
            const quotationUserName = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationUserName
                        : item.userName}
                </td>
            );
            const registerDate = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02" ? item.registerDate : ""}
                </td>
            );
            const registerTime = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02" ? item.registerTime : ""}
                </td>
            );
            const userName = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02" ? item.userName : ""}
                </td>
            );
            const clientObservation = (
                <td className="align-middle text-center font-weight-light">
                    {item.clientObservation}
                </td>
            );
            const clientName = (
                <td className="align-middle text-center font-weight-light">
                    {item.clientName}
                </td>
            );
            const clientPhysicalDistributionDisplay = (
                <td className="align-middle text-center font-weight-light">
                    {item.clientPhysicalDistributionDisplay}
                </td>
            );
            const clientVisitDayDisplay = (
                <td className="align-middle text-center font-weight-light">
                    {item.clientVisitDayDisplay}
                </td>
            );
            const clientAddress = (
                <td className="align-middle text-center font-weight-light">
                    {item.clientAddress}
                </td>
            );
            const receiptSerial = (
                <td className="align-middle text-center font-weight-light">
                    {item.receiptSerial}
                </td>
            );
            const receiptNumber = (
                <td className="align-middle text-center font-weight-light">
                    {item.receiptNumber}
                </td>
            );
            const total = (
                <td className="align-middle text-right text-nowrap font-weight-light">
                    S/ {Number(item.total).toFixed(2)}
                </td>
            );
            const quotationTotal = (
                <td className="align-middle text-right text-nowrap font-weight-light">
                    S/ {Number(item.quotationTotal).toFixed(2)}
                </td>
            );
            const operationStatus = (
                <td className="align-middle text-center font-weight-light">
                    {item.operationStatus != 200 ? (
                        <button
                            type="button"
                            className="btn btn-warning"
                            value={item.operationID}
                            onClick={(e) => {
                                console.log(item.operationID);
                                if (
                                    window.confirm(
                                        "¿Esta usted seguro de anular la venta?"
                                    )
                                ) {
                                    fetchCancelSale({
                                        operationID: item.operationID,
                                        userID: item.userID,
                                    }).then((res) => {
                                        toast.info(res.message);
                                        document
                                            .getElementById("btn-filter")
                                            .click();
                                    });
                                }
                            }}
                        >
                            <i className="fas fa-ban"></i>
                        </button>
                    ) : (
                        item.operationStatus
                    )}
                </td>
            );
            const httpStatusCode = (
                <td className="align-middle text-center font-weight-light">
                    {item.httpStatusCode !== 200 &&
                    item.receiptSerial !== null ? (
                        <>
                            {item.httpStatusCode === 400 ? (
                                <div
                                    className="alert alert-default-danger"
                                    role="alert"
                                >
                                    MENSAJE: {item.errorMessage}
                                </div>
                            ) : null}
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                value={item.operationID}
                                onClick={(e) => {
                                    console.log(item.operationID);

                                    fetchResendInvoice({
                                        operationID: item.operationID,
                                    }).then((res) => {
                                        toast.info(res.message);
                                        document
                                            .getElementById("btn-filter")
                                            .click();
                                    });
                                }}
                            >
                                REENVIAR
                            </button>
                        </>
                    ) : (
                        item.httpStatusCode
                    )}
                </td>
            );

            return (
                <tr key={index}>
                    {quotationRegisterDate}
                    {quotationRegisterTime}
                    {quotationUserName}
                    {registerDate}
                    {registerTime}
                    {userName}
                    {clientObservation}
                    {clientName}
                    {clientPhysicalDistributionDisplay}
                    {clientVisitDayDisplay}
                    {clientAddress}

                    {receiptSerial}
                    {receiptNumber}
                    {total}
                    {quotationTotal}

                    {operationStatus}
                    {httpStatusCode}
                </tr>
            );
        });
    }

    return (
        <>
            <div className="table-responsive">
                <table
                    className="table table-sm table-bordered text-center mt-3"
                    id="table-to-xls"
                >
                    <thead>
                        <tr className="text-uppercase">
                            <td colSpan={3} className="align-middle bg-navy">
                                Preventa
                            </td>
                            <td colSpan={3} className="align-middle bg-navy">
                                Despacho
                            </td>
                            <td
                                colSpan={questionState.mode == "01" ? 17 : 11}
                                className="align-middle bg-navy"
                            ></td>
                            {/*<td colSpan={3} className="align-middle bg-navy">Sunat</td>*/}
                        </tr>
                        <tr className="text-uppercase">
                            <td className="align-middle bg-navy">Fecha</td>
                            <td className="align-middle bg-navy">Hora</td>
                            <td className="align-middle bg-navy">Usuario</td>
                            <td className="align-middle bg-navy">Fecha</td>
                            <td className="align-middle bg-navy">Hora</td>
                            <td className="align-middle bg-navy">Usuario</td>

                            <td className="align-middle bg-navy">Codigo</td>

                            <td className="align-middle bg-navy">Cliente</td>

                            <td className="align-middle bg-navy">SIS. DIST</td>

                            <td className="align-middle bg-navy">Dia</td>
                            <td className="align-middle bg-navy">Direccion</td>
                            {questionState.mode == "01" ? (
                                <>
                                    <td className="align-middle bg-navy">
                                        SKU
                                    </td>
                                    <td
                                        className="align-middle bg-navy"
                                        style={{ width: "18%" }}
                                    >
                                        Producto
                                    </td>
                                    <td className="align-middle bg-navy">
                                        Unidad
                                    </td>
                                    <td className="align-middle bg-navy">
                                        Cant
                                    </td>
                                    <td className="align-middle bg-navy">
                                        Precio
                                    </td>
                                    <td className="align-middle bg-navy">
                                        Subtotal
                                    </td>
                                </>
                            ) : (
                                ""
                            )}

                            <td className="align-middle bg-navy">Serie</td>
                            <td className="align-middle bg-navy">
                                Correlativo
                            </td>
                            <td
                                className="align-middle bg-navy"
                                style={{ width: "10%" }}
                            >
                                Total
                            </td>
                            <td
                                className="align-middle bg-navy"
                                style={{ width: "10%" }}
                            >
                                Total
                                <br />
                                Preventa
                            </td>
                            <td className="align-middle bg-navy">Anular</td>
                            <td className="align-middle bg-navy">
                                Estado de envio
                            </td>
                            {/* <td className="align-middle bg-navy" >Codigo de error</td>
            <td className="align-middle bg-navy" >Mensaje de error</td> */}
                        </tr>
                    </thead>
                    <tbody>{tbodies}</tbody>

                    <tfoot>
                        <tr>
                            <td
                                className="text-right font-weight-bold"
                                colSpan={questionState.mode == "01" ? 19 : 13}
                            >
                                TOTAL
                            </td>
                            <td className="text-right text-nowrap font-weight-light">
                                S/{" "}
                                {Number(
                                    orders.reduce(
                                        (previousValue, currentValue) =>
                                            previousValue + currentValue.total,
                                        0
                                    )
                                ).toFixed(2)}
                            </td>
                            <td className="text-right text-nowrap font-weight-light">
                                S/{" "}
                                {Number(
                                    orders.reduce(
                                        (previousValue, currentValue) =>
                                            previousValue +
                                            currentValue.quotationTotal,
                                        0
                                    )
                                ).toFixed(2)}
                            </td>
                            <td
                                className="text-right font-weight-bold"
                                colSpan={1}
                            ></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <Link
                to="/buys/new_purchase_order"
                className="btn btn-outline-secondary mt-3"
            >
                {" "}
                <i className="fas fa-plus"></i> Nueva orden de compra{" "}
            </Link>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-outline-secondary mt-3 ml-1"
                table="table-to-xls"
                filename={filename}
                sheet="report"
                buttonText="Descargar como XLS"
            />
            <DetailList items={items} />
        </>
    );
}
