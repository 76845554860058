import React, { useEffect } from "react";

export function ProductList({
    filteredProducts,
    setItemProduct,
    setItemProductTariff,
}) {
    useEffect(() => {
        //console.log('products', products);
    }, []);
    return (
        <>
            <button
                className="btn btn-primary border my-3"
                data-toggle="modal"
                data-target="#modal-product"
                onClick={() => setItemProduct({})}
            >
                Agregar Nuevo Producto
            </button>

            <p className="float-right pt-4">
                Total productos: {filteredProducts.length}
            </p>
            <div className="table-responsive">
                <table
                    className="table table-bordered"
                    style={{ fontSize: "medium" }}
                >
                    <thead className="bg-gray-dark ">
                        <tr>
                            <th className="align-middle text-center text-uppercase font-weight-light">
                                #
                            </th>

                            <th
                                colSpan={3}
                                className="align-middle text-center text-uppercase font-weight-light"
                            >
                                descripcion
                            </th>

                            <th className="align-middle text-center text-uppercase font-weight-light">
                                imagen
                            </th>

                            <th
                                colSpan={3}
                                className="align-middle text-center text-uppercase font-weight-light"
                            >
                                tarifario
                            </th>
                        </tr>
                        <tr>
                            {/*<th colSpan={3} className="align-middle font-weight-light">
              <div className="row  ">
                <div className="col-6 align-self-center text-center text-uppercase">
                  almacen
                </div>
                <div className="col-6 align-self-center text-center text-uppercase">
                  stock actual
                </div>
              </div>
            </th>*/}

                            {/* <th colSpan={6}>
                                <div className="row font-weight-light">
                                    <div className="col-2 text-center text-uppercase">
                                        unidad
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        COST X UND
                                        <br />
                                        P. VEN TIENDA
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        COST CJ PEPSICO
                                        <br />
                                        P. VEN PUBLICO
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        Costo Mayorista{" "}
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        Costo Grifo Primax{" "}
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        Costo para Sunat{" "}
                                    </div>

                                    <div className="col-2 text-center text-uppercase">
                                        %dscto
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        und min
                                    </div>
                                    <div className="col-2 text-center text-uppercase">
                                        edit.
                                    </div>
                                </div>
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((item, index) => {
                            let pathDefault =
                                "https://firebasestorage.googleapis.com/v0/b/deliveryapp-e121f.appspot.com/o/guille%2Fimages%2F84e40303-c3c6-4eeb-bd3c-064afb593ba5?alt=media&token=3a87c275-6b64-40e0-801b-88b0719efa8e";
                            return (
                                <tr
                                    key={index}
                                    value={item.productID}
                                    className={
                                        item.available === true
                                            ? "bg-green small"
                                            : "bg-red small"
                                    }
                                >
                                    <td className="align-middle">
                                        <button
                                            className={
                                                item.isGroup
                                                    ? "btn btn-dark border"
                                                    : "btn btn-light border"
                                            }
                                            data-toggle="modal"
                                            data-target="#modal-product"
                                            onClick={() => {
                                                setItemProduct(item);
                                            }}
                                        >
                                            {Number(item.code).toFixed(2)}
                                        </button>
                                    </td>

                                    <td className="align-middle" colSpan={3}>
                                        SKU:{" "}
                                        <span className="font-weight-bold">
                                            {item.sku}
                                        </span>
                                        <br />
                                        NOM. COMPRA:{" "}
                                        <span className="font-weight-bold">
                                            {item.purchaseName}
                                        </span>
                                        <br />
                                        NOM. VENTA:{" "}
                                        <span className="font-weight-bold">
                                            {item.saleName}
                                        </span>
                                        <br />
                                        MARCA:{" "}
                                        <span className="font-weight-bold">
                                            {item.productBrandName}
                                        </span>
                                        <br />
                                        CLASIFICACION:{" "}
                                        <span className="font-weight-bold">
                                            {item.classification === "B"
                                                ? "BRONCE"
                                                : item.classification === "P"
                                                ? "PLATA"
                                                : "ORO"}
                                        </span>
                                        <br />
                                        ESTADO:{" "}
                                        <span className="font-weight-bold">
                                            {item.available
                                                ? "DISPONIBLE"
                                                : "NO DISPONIBLE"}
                                        </span>
                                        {item.isGroup ? (
                                            <>
                                                <br />
                                                <span className="font-weight-bold">
                                                    ES CABEZA DE GRUPO
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <br />
                                                PERTENECE AL GRUPO:{" "}
                                                <span className="font-weight-bold">
                                                    {item.parentProductName}
                                                </span>
                                                <br />
                                            </>
                                        )}
                                    </td>

                                    <td>
                                        <img
                                            onError={(ev) => {
                                                ev.target.src = pathDefault;
                                                ev.target.onerror = null;
                                            }}
                                            src={
                                                item.path
                                                    ? item.path.replaceAll(
                                                          ".png",
                                                          "_thumb_100.png"
                                                      )
                                                    : pathDefault
                                            }
                                            width={50}
                                            alt="path"
                                        />
                                    </td>

                                    {/*<td colSpan={3} className="align-middle">
                  {item.warehouseDict.map((inventory, i) => {
                    return inventory.operationWarehouseName?(

                        <div key={i} className="row mb-1 border">
                        <div className="col-8 justify-content-center d-flex align-items-center text-center">
                          {inventory.operationWarehouseName}
                        </div>
                        <div className="col-4 bg-light">
                          {inventory.remainingQuantity}
                        </div>
                      </div>
                      
                    ):"";
                  })}
                </td>*/}

                                    <td colSpan={3} className="align-middle">
                                        <div className="d-flex justify-content-end mb-2">
                                            {/* <div className="col d-flex justify-content-center align-items-center"> */}
                                            <button
                                                // className="btn btn-block btn-light border mt-1"
                                                className={
                                                    item.available === true
                                                        ? "btn btn-success btn-sm"
                                                        : "btn btn-danger btn-sm"
                                                }
                                                data-toggle="modal"
                                                data-target="#modal-product-tariff"
                                                onClick={() =>
                                                    setItemProductTariff({
                                                        productTariffID: "0",
                                                        unitID: "0",
                                                        unitName: "",
                                                        productSaleName:
                                                            item.saleName,
                                                        productID:
                                                            item.productID,
                                                        priceSale: "",
                                                        pricePurchase: "",
                                                        percentageDiscount: "",
                                                        quantityMinimum: "",
                                                    })
                                                }
                                            >
                                                <i className="fas fa-plus"></i>{" "}
                                                Nueva Presentacion
                                            </button>
                                            {/* </div> */}
                                        </div>

                                        <div className="d-flex p-1">
                                            {item.productTariffList.map(
                                                (tariff, t) => (
                                                    <div
                                                        key={t}
                                                        className="d-flex align-content-stretch flex-wrap"
                                                    >
                                                        <div className="text-uppercase">
                                                            <div className="text-uppercase">
                                                                UND MED:{" "}
                                                                {
                                                                    tariff.unitName
                                                                }
                                                            </div>
                                                            <div className="text-uppercase">
                                                                {tariff.unitName ===
                                                                "UND"
                                                                    ? "PRECIO TIENDA: S/"
                                                                    : "COSTO X UND PEPSICO: S/"}
                                                                {Number(
                                                                    tariff.priceSale
                                                                ).toFixed(6)}
                                                            </div>

                                                            {tariff.unitName ===
                                                            "UND" ? (
                                                                <>
                                                                    <div className="text-uppercase">
                                                                        Precio
                                                                        Mayorista:{" "}
                                                                        {tariff.unitName ===
                                                                        "UND"
                                                                            ? `S/${Number(
                                                                                  tariff.priceSaleWholesale
                                                                              ).toFixed(
                                                                                  2
                                                                              )}`
                                                                            : "-"}
                                                                    </div>
                                                                    <div className="text-uppercase">
                                                                        Precio
                                                                        Grifo
                                                                        Primax:{" "}
                                                                        {tariff.unitName ===
                                                                        "UND"
                                                                            ? `S/${Number(
                                                                                  tariff.priceSalePrimax
                                                                              ).toFixed(
                                                                                  2
                                                                              )}`
                                                                            : "-"}
                                                                    </div>
                                                                    <div className="text-uppercase">
                                                                        Precio
                                                                        Sunat:{" "}
                                                                        {tariff.unitName ===
                                                                        "UND"
                                                                            ? `S/${Number(
                                                                                  tariff.priceSaleSunat
                                                                              ).toFixed(
                                                                                  2
                                                                              )}`
                                                                            : "-"}
                                                                    </div>
                                                                </>
                                                            ) : null}

                                                            {tariff.unitName !==
                                                            "UND" ? (
                                                                <>
                                                                    <div className="text-uppercase">
                                                                        COSTO X
                                                                        CJ
                                                                        PEPSICO:
                                                                        S/
                                                                        {Number(
                                                                            tariff.pricePurchase
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                    </div>
                                                                    <div className="text-uppercase">
                                                                        %dscto:{" "}
                                                                        {
                                                                            tariff.percentageDiscount
                                                                        }
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                            <div className="text-uppercase">
                                                                und min:{" "}
                                                                {
                                                                    tariff.quantityMinimum
                                                                }
                                                            </div>
                                                        </div>

                                                        <button
                                                            // className="btn btn-light border"
                                                            className={
                                                                item.available ===
                                                                true
                                                                    ? "btn btn-success"
                                                                    : "btn btn-danger"
                                                            }
                                                            data-toggle="modal"
                                                            data-target="#modal-product-tariff"
                                                            onClick={() => {
                                                                tariff.productSaleName =
                                                                    item.saleName;
                                                                setItemProductTariff(
                                                                    tariff
                                                                );
                                                            }}
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
