import React, { useState } from "react";
import { getCurrentDate } from "../../../configs/helpers";
import { AccountReceiveSearch } from "../fragments.accounting/AccountReceiveSearch";
import { fetchedAllOperationsAccountReceiveByClient } from "../../../apis/apis.accounting/apisCash";
import { AccountReceiveOperations } from "../fragments.accounting/AccountReceiveOperations";
import { AccountReceiveList } from "../fragments.accounting/AccountReceiveList";

const initialCashFlowState = {
    transactionDate: getCurrentDate("-"),
    description: "",
    type: "E",
    operationID: 0,
    total: 0,
    userID: 0,
};

export function AccountReceivePage() {
    const [sales, setSales] = useState([]);
    const [cashFlow, setCashFlow] = useState(initialCashFlowState);
    const [cashFlowSet, setCashFlowSet] = useState([]);
    const [dates, setDates] = useState({
        startDate: getCurrentDate("-"),
        endDate: getCurrentDate("-"),
    });

    const [debtorItem, setDebtorItem] = useState({
        debtorCashID: "0",
        debtorCashAmount: "0",
        debtorCashName: "",
        debtorCashStatus: "",
        debtorClientID: "0",
        debtorClientName: "",
        debtorClientVisitDay: "0",
    });

    const getAllAccountReceiveList = async (data) => {
        try {
            const response = await fetchedAllOperationsAccountReceiveByClient({
                clientID: debtorItem.debtorClientID,
                startDate: dates.startDate,
                endDate: dates.endDate,
            });
            setSales(response);

            return response;
        } catch (error) {
            throw error;
        }
    };

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Cuentas por cobrar</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">Finanzas</li>
                                <li className="breadcrumb-item active">
                                    Cuentas por cobrar
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-body">
                            <AccountReceiveSearch
                                setDebtorItem={setDebtorItem}
                                debtorItem={debtorItem}
                                dates={dates}
                                setDates={setDates}
                                getAllAccountReceiveList={
                                    getAllAccountReceiveList
                                }
                            />

                            <AccountReceiveList
                                sales={sales}
                                setCashFlow={setCashFlow}
                                setCashFlowSet={setCashFlowSet}
                            />
                            <AccountReceiveOperations
                                setCashFlow={setCashFlow}
                                cashFlow={cashFlow}
                                cashFlowSet={cashFlowSet}
                                setCashFlowSet={setCashFlowSet}
                                getAllAccountReceiveList={
                                    getAllAccountReceiveList
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
