import { BASE_API } from "../../configs/ConfigsConstants";

export async function fetchedVehiclesBySubsidiary(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/get_vehicles_by_subsidiary/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendSupplyingData(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/register_supplying/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function registerClientData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/register_client/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function updateClientData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/update_client/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendReadjustmentData(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/register_readjustment/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendDevolutionData(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/register_devolution/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedDispatchesByWarehouse(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/filter_dispatches_by_action/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedClientPortfolio(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/client_portfolio/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedDispatchDetailsByWarehouse(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/filter_dispatch_details_by_action/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedPreSalesByAction(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/filter_pre_sales_by_action/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedStockInWarehouse(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/get_stock_in_warehouse/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedSalesAccumulative(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/get_sales_accumulative_by_action/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedUsersByGang(data) {
    try {
        const url = `${BASE_API}/sales/api/v1/get_users_by_gang/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function fetchedRoute(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/get_route/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendConfirmDispatchData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/terminate_quotation/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendCancelPresaleData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/annul_presale_from_the_web/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendCancelSaleData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/annul_sale_from_the_web/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendPresaleDeliveredData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/presale_delivered/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function sendPresaleNotDeliveredData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/presale_no_delivered/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function putPresaleInPendingData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/put_in_pending/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function getSalesForChartPieData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/get_actual_sales_for_chart_pie/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function getActualSalesOfWeekData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/get_actual_sales_of_week_for_bar_char/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function getActualSoldProductsData(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/get_actual_sold_products_for_bar_char/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}

export async function resendInvoice(data) {
    try {
        const url = `${BASE_API}/dispatches/api/v1/resend_invoice/`;
        const params = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, params);
        const result = await response.json();
        //console.log(result);
        return result;
    } catch (error) {
        throw error;
    }
}
