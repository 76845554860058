import React, { useContext, useEffect } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import {
    savePayment,
    removePayment,
} from "../../../apis/apis.accounting/apisCash";
import { toast } from "react-toastify";

export function AccountReceiveOperations({
    setCashFlow,
    cashFlow,
    cashFlowSet,
    setCashFlowSet,
    getAllAccountReceiveList,
}) {
    const { auth } = useContext(ContextAuth);

    useEffect(() => {
        if (auth && cashFlow.userID === 0) {
            setCashFlow({ ...cashFlow, userID: auth.data.user_id });
        }
    }, [auth, cashFlow, setCashFlow]);

    function handleChange(e) {
        if (e.target.name === "total") {
            let formattedValue = e.target.value
                .replace(/[^0-9.]/g, "")
                .slice(0, 9);
            setCashFlow({ ...cashFlow, total: formattedValue });
        } else {
            setCashFlow({ ...cashFlow, [e.target.name]: e.target.value });
        }
    }
    const saveAndGetPayments = async (data) => {
        try {
            const response = await savePayment(data);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleSave = (e) => {
        e.preventDefault();
        if (Number(cashFlow.operationID) === 0) {
            toast.warning("No hay operacion vinculada.");
            return;
        }
        if (Number(cashFlow.total) === 0) {
            toast.warning("No hay monto.");
            return;
        }

        saveAndGetPayments(cashFlow).then(async (res) => {
            setCashFlowSet(res.cashFlowSet);
            getAllAccountReceiveList();
        });

        document.getElementById("closeCollectModal").click();
    };
    const removeAndGetPayments = async (cashFlowID) => {
        console.log("cashFlowID", cashFlowID);
        try {
            const response = await removePayment({
                cashFlowID: cashFlowID,
            });
            setCashFlowSet(response);
            getAllAccountReceiveList();
        } catch (error) {
            throw error;
        }
    };
    return (
        <div
            className="modal fade"
            id="collectModal"
            tabIndex="-1"
            aria-labelledby="collectModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="collectModalLabel">
                            Efectuar Pago
                        </h5>
                        <button
                            type="button"
                            id="closeCollectModal"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-end mb-2">
                            <div className="form-group mr-2 mb-0">
                                <label htmlFor="wayPayType">Tipo de pago</label>
                                <select
                                    className="form-control"
                                    id="wayPayType"
                                    name="type"
                                    value={cashFlow.type}
                                    onChange={handleChange}
                                >
                                    <option value={"E"}>EFECTIVO</option>
                                    <option value={"DY"}>YAPE</option>
                                    {/* <option value={"DC"}>CREDITO</option> */}
                                </select>
                            </div>

                            <div className="form-group mr-2 mb-0">
                                <label htmlFor="wayPayDescription">
                                    Descripcion
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="wayPayDescription"
                                    placeholder="Descripcion"
                                    name="description"
                                    value={cashFlow.description}
                                    onChange={handleChange}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>

                            <div className="form-group mr-2 mb-0">
                                <label htmlFor="transactionDate1">Fecha</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="transactionDate1"
                                    name="transactionDate"
                                    value={cashFlow.transactionDate}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group mr-2 mb-0">
                                <label htmlFor="wayPayTotal">Total</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="wayPayTotal"
                                    placeholder="Total"
                                    name="total"
                                    value={cashFlow.total}
                                    onChange={handleChange}
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>

                            <button
                                className="btn btn-success"
                                onClick={handleSave}
                            >
                                Agregar
                            </button>
                        </div>
                        {cashFlowSet?.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-sm table-bordered text-sm">
                                    <thead>
                                        <tr className="bg-dark">
                                            <th scope="col">#</th>
                                            <th scope="col">DESCRIPCION</th>
                                            <th scope="col">FECHA</th>
                                            <th scope="col">TIPO</th>
                                            <th scope="col">TOTAL</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cashFlowSet?.map((item, c) => (
                                            <tr key={c}>
                                                <th className="align-middle">
                                                    {item.id}
                                                </th>
                                                <td className="align-middle">
                                                    {item.description}
                                                </td>
                                                <td className="align-middle">
                                                    {item.transactionDate}
                                                </td>
                                                <td className="align-middle">
                                                    {item.type === "DC"
                                                        ? "DEBE "
                                                        : item.type === "DY"
                                                        ? "YAPE "
                                                        : "EFECTIVO "}
                                                </td>
                                                <td className="align-middle">
                                                    S/{" "}
                                                    {Number(item.total).toFixed(
                                                        2
                                                    )}
                                                </td>
                                                <td className="align-middle">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-link"
                                                        onClick={() => {
                                                            removeAndGetPayments(
                                                                item.id
                                                            );
                                                        }}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
