import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchedDebtors } from "../../../apis/apis.accounting/apisCash";
import Select from "react-select";
import { customStyles } from "../../../configs/Data";

export function AccountReceiveSearch({
    setDebtorItem,
    debtorItem,
    setDates,
    dates,
    getAllAccountReceiveList,
}) {
    const [debtors, setDebtors] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    function handleSelectDateChange(e) {
        setDates({
            ...dates,
            [e.target.name]: e.target.value,
        });
    }

    const getClientsWithDebt = async () => {
        try {
            const response = await fetchedDebtors();
            response.forEach((mobile) => {
                setDebtors((prevState) => [
                    ...prevState,
                    {
                        value: mobile["clientID"],
                        label: mobile["clientName"],
                        clientName: mobile["clientName"],
                        clientVisitDay: mobile["clientVisitDay"],
                    },
                ]);
            });
        } catch (error) {
            throw error;
        }
    };

    const handleSelectChange = async (selectOption) => {
        console.log("selectOption", selectOption);
        setSelectedOption(selectOption);
        setDebtorItem({
            ...debtorItem,
            debtorClientID: selectOption.value,
            debtorClientName: selectOption.clientName,
            debtorClientVisitDay: selectOption.clientVisitDay,
        });
        // setSelectedOption(null);
    };

    function searchAccountsReceiveList(params) {
        if (
            debtorItem.debtorClientID !== "0" &&
            dates.startDate !== "" &&
            dates.endDate !== ""
        ) {
            getAllAccountReceiveList();
            setSelectedOption(null);
        } else {
            toast.warn("SELECCIONE UN CLIENTE..");
        }
    }

    useEffect(() => {
        getClientsWithDebt();
    }, []);

    return (
        <div>
            <div className="card-body text-center font-weight-bolder pl-2">
                <div className="card bg-light ">
                    <div className="card-body pb-0">
                        <div className="row">
                            <div className="col-auto">
                                <div className="form-group row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-auto col-form-label"
                                    >
                                        DESDE:
                                    </label>
                                    <div className="col-auto">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="startDate"
                                            value={dates.startDate}
                                            onChange={handleSelectDateChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-auto">
                                <div className="form-group row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-auto col-form-label"
                                    >
                                        HASTA:
                                    </label>
                                    <div className="col-auto">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="endDate"
                                            value={dates.endDate}
                                            onChange={handleSelectDateChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <Select
                                    options={debtors}
                                    value={selectedOption}
                                    styles={customStyles}
                                    placeholder={"SELECCIONE CLIENTE"}
                                    onChange={handleSelectChange}
                                />
                            </div>

                            <div className="col-auto">
                                <button
                                    type="button"
                                    className="btn btn-block btn-primary"
                                    onClick={searchAccountsReceiveList}
                                    id="btn-search"
                                >
                                    Buscar <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
