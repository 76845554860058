import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { fetchedAllPaymentsByOperation } from "../../../apis/apis.accounting/apisCash";

const filename = "Reporte_estado_de_cuentas";

export function AccountReceiveList({ sales, setCashFlow, setCashFlowSet }) {
    const [summary, setSummary] = useState({
        sumTotalPurchased: 0,
        sumTotalPaid: 0,
        sumTotalPending: 0,
    });

    useEffect(() => {
        const sumTotalPurchased = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPurchased);
            }, 0);

        const sumTotalPaid = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPaid);
            }, 0);

        const sumTotalPending = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPending);
            }, 0);

        setSummary({
            sumTotalPurchased,
            sumTotalPaid,
            sumTotalPending,
        });
    }, [sales]);

    const getAllPayments = async (operationID) => {
        try {
            const response = await fetchedAllPaymentsByOperation({
                operationID,
            });
            setCashFlowSet(response);
        } catch (error) {
            throw error;
        }
    };
    return (
        <>
            <table
                className="table table-sm table-bordered text-uppercase"
                id="table-to-xls"
            >
                <thead>
                    <tr className="bg-light">
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            ID
                        </td>

                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            DOCUMENTO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            FECHA/HORA
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            USUARIO
                        </td>

                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            CARGO
                        </td>

                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            ABONO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase text-primary"
                        >
                            SALDO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        ></td>
                    </tr>
                </thead>
                <tbody>
                    {sales?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                value={item.operationID}
                                className={
                                    item.operationStatus === "03"
                                        ? "bg-danger"
                                        : item.operationType === "01"
                                        ? "bg-warning"
                                        : item.operationType === "02"
                                        ? "bg-light"
                                        : "text-dark"
                                }
                            >
                                <td className="align-middle small text-info">
                                    {item.operationID}
                                </td>

                                <td className="align-middle text-sm font-weight-bold">
                                    {item.receiptSerial} - {item.receiptNumber}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.registerDate} {item.registerTime}
                                </td>
                                <td className="align-middle text-sm">
                                    {`${item.userName?.substring(0, 5)}...`}
                                </td>

                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPurchased
                                        ? `S/ ${Number(
                                              item.totalPurchased
                                          ).toFixed(2)}`
                                        : "S/ 0.00"}
                                </td>
                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPaid
                                        ? `S/ ${Number(item.totalPaid).toFixed(
                                              2
                                          )}`
                                        : "S/ 0.00"}
                                </td>

                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPending
                                        ? `S/ ${Number(
                                              item.totalPending
                                          ).toFixed(2)}`
                                        : "S/ 0.00"}
                                </td>
                                <td className="align-middle text-center">
                                    {item.operationStatus === "02" ? (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCashFlow((prevCashFlow) => ({
                                                    ...prevCashFlow,
                                                    description: "",
                                                    type: "E",
                                                    operationID: Number(
                                                        item.operationID
                                                    ),
                                                    total: Number(
                                                        item.totalPending
                                                    ),
                                                }));
                                                getAllPayments(
                                                    item.operationID
                                                );
                                            }}
                                            data-toggle="modal"
                                            data-target="#collectModal"
                                            className={
                                                Number(item.totalPending) > 0
                                                    ? "btn py-0 bg-red text-sm"
                                                    : "btn py-0 btn-link text-sm"
                                            }
                                        >
                                            {Number(item.totalPending) > 0
                                                ? "PAGAR"
                                                : "VER"}
                                        </button>
                                    ) : null}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            className="align-middle text-sm text-right"
                            colSpan={4}
                        >
                            TOTAL
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPurchased).toFixed(
                                2
                            )}`}
                        </td>

                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPaid).toFixed(2)}`}
                        </td>

                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPending).toFixed(2)}`}
                        </td>

                        <td></td>
                    </tr>
                </tfoot>
            </table>

            <ReactHTMLTableToExcel
                id="table-xls-button"
                className="btn btn-outline-success mt-3 ml-1"
                table="table-to-xls"
                filename={filename}
                sheet="report"
                buttonText="Descargar como XLS"
            />
        </>
    );
}
