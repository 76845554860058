import { ProductList } from "../fragments.logistics/ProductList";
import { ProductFilter } from "../fragments.logistics/ProductFilter";
import { ProductForm } from "../fragments.logistics/ProductForm";
import { ProductTariffForm } from "../fragments.logistics/ProductTariffForm";
import React, { useState, useEffect } from "react";
import {
    fetchedProducts,
    fetchedBrands,
    fetchedUnits,
    fetchedGroupOfProducts,
} from "../../../apis/apis.logistics/apisProduct";

const initialProductTariffState = {
    productTariffID: "0",
    productID: "0",
    productSaleName: "",
    unitID: "0",
    unitName: "",
    priceSale: "",
    pricePurchase: "",
    percentageDiscount: "",
    quantityMinimum: "",
};

export function ProductPage() {
    const [units, setUnits] = useState([]);
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState([]);
    const [itemProduct, setItemProduct] = useState({});
    const [itemProductTariff, setItemProductTariff] = useState(
        initialProductTariffState
    );
    const [brands, setBrands] = useState([]);
    const [groupOfProducts, setGroupOfProducts] = useState([]);

    const [filters, setFilters] = useState({
        code: "",
        purchaseName: "",
        saleName: "",
        sku: "",
        productBrandName: "",
        priceSale: "",
        available: "true",
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleFilterChangeForAutocomplete = (field, value) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };

    // Filtrar los productos en función de los filtros seleccionados
    const filteredProducts = products.filter((product) => {
        return (
            (filters.code === "" ||
                (product.code?.toString() || "").includes(filters.code)) &&
            (filters.purchaseName === "" ||
                (product.purchaseName.toLowerCase() || "").includes(
                    filters.purchaseName.toLowerCase()
                )) &&
            (filters.saleName === "" ||
                (product.saleName?.toLowerCase() || "").includes(
                    filters.saleName?.toLowerCase()
                )) &&
            (filters.sku === "" || (product.sku || "").includes(filters.sku)) &&
            (filters.productBrandName === "" ||
                (product.productBrandName || "").includes(
                    filters.productBrandName
                )) &&
            (filters.priceSale === "" ||
                product.productTariffList.some(
                    (tariff) =>
                        !filters.priceSale ||
                        Number(tariff.priceSale) === Number(filters.priceSale)
                )) &&
            (filters.available === "" ||
                product.available === (filters.available === "true"))
        );
    });

    // Obtener valores únicos para los selectores
    const getUniqueValues = (key) => {
        return [...new Set(products.map((product) => product[key]))];
    };

    const getProducts = async () => {
        try {
            const dataProducts = await fetchedProducts();
            const isUnitTariff = (tariff) => tariff.unitID === 1;
            const dataPrices = dataProducts
                .filter((p) => p.productTariffList.length === 2)
                .map((p) => {
                    return p.productTariffList.find(isUnitTariff).priceSale;
                });

            const uniquePrices = Object.keys(
                dataPrices.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
            );
            const sortedPrices = uniquePrices.map(Number).sort((a, b) => a - b);
            setPrices(sortedPrices);
            setProducts(dataProducts);
            return dataProducts;
        } catch (error) {
            throw error;
        }
    };

    const getGroupOfProducts = async () => {
        try {
            const response = await fetchedGroupOfProducts();
            setGroupOfProducts(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getBrands = async () => {
        try {
            const response = await fetchedBrands();
            setBrands(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const getUnits = async () => {
        try {
            const response = await fetchedUnits();
            setUnits(response);
            return response;
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        getProducts();
        getBrands();
        getUnits();
        getGroupOfProducts();
    }, []);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Modulo Logistico</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Logistica</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Productos
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header"></div>
                        <div className="card-body">
                            <ProductFilter
                                filters={filters}
                                handleFilterChange={handleFilterChange}
                                handleFilterChangeForAutocomplete={
                                    handleFilterChangeForAutocomplete
                                }
                                getUniqueValues={getUniqueValues}
                                products={products}
                                prices={prices}
                            />
                            <ProductList
                                filteredProducts={filteredProducts}
                                setItemProduct={setItemProduct}
                                setItemProductTariff={setItemProductTariff}
                                setProducts={setProducts}
                            />
                            <ProductForm
                                itemProduct={itemProduct}
                                products={products}
                                brands={brands}
                                groupOfProducts={groupOfProducts}
                                getGroupOfProducts={getGroupOfProducts}
                                setProducts={setProducts}
                                getProducts={getProducts}
                            />
                            <ProductTariffForm
                                units={units}
                                products={products}
                                itemProductTariff={itemProductTariff}
                                setItemProductTariff={setItemProductTariff}
                                setProducts={setProducts}
                                initialProductTariffState={
                                    initialProductTariffState
                                }
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

/*
              
              

*/
