import React, { useState } from "react";

const AutocompleteFilter = ({ options, label, value, onChange }) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        onChange(inputValue);

        // Filtrar las opciones según el input
        const matches = options.filter(
            (option) =>
                option &&
                option.toLowerCase().includes(inputValue?.toLowerCase() || "")
        );
        setFilteredOptions(matches);
        setShowOptions(inputValue !== "");
    };

    const handleOptionClick = (option) => {
        onChange(option); // Selecciona la opción
        setShowOptions(false); // Oculta las sugerencias
    };

    return (
        // <div style={{ position: "relative" }}>
        <>
            <label>{label}</label>
            <input
                type="text"
                value={value}
                className="form-control form-control-sm"
                onChange={handleInputChange}
                onBlur={() => setShowOptions(false)} // Oculta opciones al perder el foco
                onFocus={() => setShowOptions(value !== "")} // Muestra opciones al ganar el foco
                // style={{
                //     width: "100%",
                //     padding: "8px",
                //     boxSizing: "border-box",
                // }}
            />
            {showOptions && (
                <ul
                    style={{
                        position: "absolute",
                        zIndex: 1,
                        background: "white",
                        border: "1px solid #ccc",
                        listStyle: "none",
                        padding: "0",
                        margin: "0",
                        width: "100%",
                        maxHeight: "150px",
                        overflowY: "auto",
                    }}
                >
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #eee",
                                }}
                            >
                                {option}
                            </li>
                        ))
                    ) : (
                        <li style={{ padding: "8px" }}>Sin resultados</li>
                    )}
                </ul>
            )}
        </>
    );
};

export function ProductFilter({
    filters,
    handleFilterChange,
    handleFilterChangeForAutocomplete,
    getUniqueValues,
    products,
    prices,
}) {
    // const filterProducts = async (data) => {
    //     try {
    //         const response = await sendFilterProductData(data);
    //         //console.log(response);
    //         return response;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    //state to hold responses from input
    // const [questionState, setQuestionState] = useState({
    //     options: [{ radioName: "actions", selected: "purchaseName" }],
    //     suggest: "",
    // });

    //radio buttons onchange
    // const handleChange = (e) => {
    //     const { options } = questionState;
    //     const { name, value } = e.target;
    //     console.log(name, value);

    //     const nextState = options.map((opt) => {
    //         if (opt.radioName !== name) {
    //             return { ...opt };
    //         } else {
    //             return { ...opt, selected: value };
    //         }
    //     });

    //     setQuestionState({ ...questionState, options: nextState });
    //     //console.log(questionState)
    // };

    // function handleChangeSuggest(e) {
    //     setQuestionState({ ...questionState, suggest: e.target.value });
    // }

    // const handleKeyDownSupplier = async (e) => {
    //     if (e.keyCode === 13) {
    //         searchSuggestions();
    //     }
    // };

    // const handleQuery = (e) => {
    //     searchSuggestions();
    // }

    // function searchSuggestions() {
    //     filterProducts(questionState).then((res) => {
    //         let results = res.products.length;
    //         toast.info(
    //             results > 1
    //                 ? "Se encontraron " + results.toString() + " resultados."
    //                 : "Se encontró " + results.toString() + " resultado(s)."
    //         );
    //         props.setProducts(res.products);
    //     });
    // }

    /*useEffect(() => {}, []); // <-- Have to pass in [] here!*/

    const saleNameOptions = [
        ...new Set(products.map((product) => product.saleName?.toLowerCase())),
    ];
    const skuOptions = [...new Set(products.map((product) => product.sku))];
    // const codeOptions = [...new Set(products.map((product) => product.code))];

    const purchaseNameOptions = [
        ...new Set(
            products.map((product) => product.purchaseName?.toLowerCase())
        ),
    ];

    return (
        <div className="border bg-light rounded p-3">
            {/* <div className="row">
                <div className="col-auto pt-2 text-cyan">Buscar x</div>
                <div className="col-auto pt-2">
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action1"
                            value="code"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action1">
                            codigo
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action2"
                            value="purchaseName"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action2">
                            nombre de compra
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action3"
                            value="saleName"

                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action3">
                            nombre de venta
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action4"
                            value="sku"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action4">
                            sku
                        </label>
                    </div>
                    <span className="mr-3">{" / "}</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="actions"
                            id="action5"
                            value="brand"
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="action5">
                            marca
                        </label>
                    </div>
                </div>
                <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        name="suggest"
                        placeholder="Ingrese Texto (3 caracteres como minimo)"
                        onChange={handleChangeSuggest}
                        onKeyDown={handleKeyDownSupplier}
                        maxLength={50}
                        autoComplete="off"
                    />
                </div>
                <div className="col-auto text-cyan">P. V. (Tienda)</div>
                <div className="col-auto">

                </div>
                <div className="col-auto">
                    <button className="btn btn-light border" onClick={handleQuery}>
                        Filtrar
                    </button>
                </div>
            </div> */}

            <h2>Lista de Productos</h2>
            <div className="form-row">
                <div className="form-group col-md-2">
                    <label htmlFor="selectCode">Código:</label>
                    <select
                        id="selectCode"
                        name="code"
                        value={filters.code}
                        className="form-control form-control-sm"
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {getUniqueValues("code").map((code) => (
                            <option key={code} value={code}>
                                {code}
                            </option>
                        ))}
                    </select>
                </div>

                {/* <AutocompleteFilter
                    label="Código"
                    options={codeOptions}
                    value={filters.code}
                    onChange={(value) =>
                        handleFilterChangeForAutocomplete("code", value)
                    }
                /> */}
                {/* <label>
                    Nombre de Compra:
                    <select
                        name="purchaseName"
                        value={filters.purchaseName}
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {getUniqueValues("purchaseName").map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </label> */}
                <div className="form-group col-md-2">
                    <AutocompleteFilter
                        label="SKU"
                        options={skuOptions}
                        value={filters.sku}
                        onChange={(value) =>
                            handleFilterChangeForAutocomplete("sku", value)
                        }
                    />
                </div>
                <div className="form-group col-md-8"></div>
                <div className="form-group col-md-6">
                    <AutocompleteFilter
                        label="Nombre de Compra"
                        options={purchaseNameOptions}
                        value={filters.purchaseName}
                        onChange={(value) =>
                            handleFilterChangeForAutocomplete(
                                "purchaseName",
                                value
                            )
                        }
                    />
                </div>
                {/* <label>
                    Nombre de Venta:
                    <select
                        name="saleName"
                        value={filters.saleName}
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {getUniqueValues("saleName").map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                    
                </label> */}
                <div className="form-group col-md-6">
                    <AutocompleteFilter
                        label="Nombre de Venta"
                        options={saleNameOptions}
                        value={filters.saleName}
                        onChange={(value) =>
                            handleFilterChangeForAutocomplete("saleName", value)
                        }
                    />
                </div>
                {/* <label>
                    SKU:
                    <select
                        name="sku"
                        value={filters.sku}
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {getUniqueValues("sku").map((sku) => (
                            <option key={sku} value={sku}>
                                {sku}
                            </option>
                        ))}
                    </select>
                </label> */}

                <div className="form-group col-md-8">
                    <label>Marca:</label>
                    <select
                        name="productBrandName"
                        value={filters.productBrandName}
                        className="form-control form-control-sm"
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {getUniqueValues("productBrandName").map((brand) => (
                            <option key={brand} value={brand}>
                                {brand}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="selectPriceSale">Precio Tienda:</label>
                    <select
                        id="selectPriceSale"
                        name="priceSale"
                        value={filters.priceSale}
                        className="form-control form-control-sm"
                        onChange={handleFilterChange}
                    >
                        <option value="">Todos</option>
                        {prices.map((price, index) => (
                            <option key={index} value={price}>
                                {price}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Estado:</label>
                    <select
                        name="available"
                        value={filters.available}
                        className="form-control form-control-sm"
                        onChange={handleFilterChange}
                    >
                        <option value="true">Activo</option>
                        <option value="false">No Activo</option>
                        <option value="">Todos</option>
                    </select>
                </div>
            </div>
        </div>
    );
}
