import React, { useState, useEffect, useContext } from "react";
import { ContextAuth } from "../../../contexts/ContextAuth";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DetailList } from "./DetailList";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    sendCancelPresaleData,
    sendPresaleDeliveredData,
    sendPresaleNotDeliveredData,
    putPresaleInPendingData,
} from "../../../apis/apis.dispatches/apisDispatch";

export function OrderTrayList({
    setOrders,
    orders,
    filename,
    setDetailsFromOrderDispatchReceived,
    setDispatchToConfirm,
    dispatchToConfirm,
}) {
    const [items, setItems] = useState([]);
    const [hostname, setHostname] = useState(null);
    const operationValues = Object.values(orders);
    const { auth } = useContext(ContextAuth);
    const sendCancelPresale = async () => {
        try {
            const response = await sendCancelPresaleData(dispatchToConfirm);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const sendPresaleDelivered = async (o) => {
        try {
            const response = await sendPresaleDeliveredData(o);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const putPresaleInPending = async (o) => {
        try {
            const response = await putPresaleInPendingData(o);
            return response;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        if (hostname == null) {
            if (window.location.hostname !== "localhost")
                setHostname("https://www.deldiadistribuciones.nom.pe");
            else setHostname("http://192.168.1.22:9017");
        }
    }, [hostname]);

    useEffect(() => {
        if (
            "observation" in dispatchToConfirm &&
            dispatchToConfirm["operationID"] != 0
        )
            sendCancelPresale()
                .then((r) => {
                    toast.success(
                        "Preventa anulada con exito! " + r.documentNumber
                    );
                    document.getElementById("btn-filter").click();
                    setDispatchToConfirm({
                        ...dispatchToConfirm,
                        observation: "",
                        operationID: 0,
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
    }, [dispatchToConfirm.observation]);

    const tbodies = operationValues.map((item, i) => {
        return (
            <tr key={i} className={"bg-" + item.background}>
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationRegisterDate
                        : item.registerDate}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationRegisterTime
                        : item.registerTime}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02" ? item.registerDate : ""}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02" ? item.registerTime : ""}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.operationType == "02"
                        ? item.quotationUserName
                        : item.userName}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.dailyRouteStatus == "04" ? (
                        <div
                            className="alert alert-warning m-0 p-1 small"
                            role="alert"
                        >
                            {item.dailyRouteDisplayStatus}
                        </div>
                    ) : item.dailyRouteStatus == "05" ? (
                        <div
                            className="alert bg-blue m-0 p-1 small"
                            role="alert"
                        >
                            {item.dailyRouteDisplayStatus}
                        </div>
                    ) : item.dailyRouteStatus == "06" ? (
                        <div
                            className="alert bg-green m-0 p-1 small"
                            role="alert"
                        >
                            {item.dailyRouteDisplayStatus}
                        </div>
                    ) : item.dailyRouteStatus == "07" ? (
                        <div
                            className="alert bg-purple m-0 p-1 small"
                            role="alert"
                        >
                            {item.dailyRouteDisplayStatus}
                        </div>
                    ) : item.dailyRouteStatus == "08" ? (
                        <div
                            className="alert alert-danger m-0 p-1 small"
                            role="alert"
                        >
                            {item.dailyRouteDisplayStatus}
                        </div>
                    ) : (
                        ""
                    )}
                </td>

                <td className="align-middle text-center font-weight-light">
                    {item.clientObservation}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.clientName}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.clientPhysicalDistributionDisplay}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.clientVisitDayDisplay}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.clientAddress}
                </td>

                <td className="align-middle text-center font-weight-light">
                    {item.receiptSerial}
                </td>
                <td className="align-middle text-center font-weight-light">
                    {item.receiptNumber}
                </td>
                <td className="align-middle text-right font-weight-light">
                    S/ {Number(item.total).toFixed(2)}
                </td>
                <td className="align-middle text-right font-weight-light">
                    <div className="d-flex flex-row justify-content-center">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setDetailsFromOrderDispatchReceived(
                                    item.operationDetailDict
                                );
                            }}
                            title="Ver detalles"
                            data-toggle="modal"
                            data-target="#modal-order-dispatch-received"
                        >
                            <i className="fas fa-info-circle"></i>
                        </button>

                        {item.dailyRouteStatus == "04" ? (
                            <>
                                <button
                                    type="button"
                                    className="btn bg-red"
                                    title="Anular"
                                    onClick={(e) => {
                                        if (
                                            window.confirm(
                                                "¿Esta usted seguro de anular la preventa?"
                                            )
                                        ) {
                                            let obs = prompt(
                                                "Por favor, ingresa el motivo de la anulacion",
                                                "Anulado por cambio de producto o cancelación de la compra por parte del cliente"
                                            );
                                            if (obs != null)
                                                setDispatchToConfirm({
                                                    ...dispatchToConfirm,
                                                    observation: obs,
                                                    operationID:
                                                        item.operationID,
                                                });
                                            else
                                                toast.info(
                                                    "Preventa sin observacion"
                                                );
                                        }
                                    }}
                                >
                                    <i className="fas fa-ban"></i>
                                </button>

                                <button
                                    type="button"
                                    className="btn bg-blue mx-1"
                                    onClick={() => {
                                        setDispatchToConfirm({
                                            ...dispatchToConfirm,
                                            clientID: item.clientID,
                                            operationID: item.operationID,
                                            details: item.operationDetailDict,
                                            total: Number(item.total).toFixed(
                                                2
                                            ),
                                            documentType:
                                                item.clientDocumentType == "01"
                                                    ? "02"
                                                    : item.clientDocumentType ==
                                                      "06"
                                                    ? "03"
                                                    : "01",
                                            physicalDistribution:
                                                item.clientPhysicalDistribution,
                                        });
                                    }}
                                    title="Confirmar pedidos"
                                    data-toggle="modal"
                                    data-target="#modal-confirm-dispatch"
                                >
                                    <i className="fas fa-check"></i>
                                </button>
                            </>
                        ) : item.dailyRouteStatus == "05" ? (
                            <>
                                <button
                                    type="button"
                                    className="btn bg-teal mx-1"
                                    onClick={(e) => {
                                        sendPresaleDelivered(item).then(() => {
                                            toast.success(
                                                "Entrega realizada con exito! "
                                            );
                                            document
                                                .getElementById("btn-filter")
                                                .click();
                                        });
                                    }}
                                    title="Entregado"
                                >
                                    <i className="fas fa-thumbs-up"></i>
                                </button>

                                <button
                                    type="button"
                                    className="btn bg-purple"
                                    onClick={async (e) => {
                                        let obs = prompt(
                                            "Por favor, ingresa el motivo de la falla en la entrega",
                                            "El cliente no estaba presente al momento de la entrega"
                                        );
                                        if (obs != null) {
                                            item.observation = obs;
                                            item.userID = auth.data.user_id;
                                            await sendPresaleNotDeliveredData(
                                                item
                                            ).then(() => {
                                                toast.success(
                                                    "Falla en la entrega registrada con exito! "
                                                );
                                                document
                                                    .getElementById(
                                                        "btn-filter"
                                                    )
                                                    .click();
                                            });
                                        } else
                                            toast.info(
                                                "Cancelacion de entrega sin observacion"
                                            );
                                    }}
                                    title="No entregado"
                                >
                                    <i className="fas fa-thumbs-down"></i>
                                </button>
                                <a
                                    className="btn btn-warning ml-1"
                                    href={`${hostname}/dispatches/print_ticket/${item.operationID}/`}
                                    target="_blank"
                                    title="Descargar PDF"
                                    download
                                    onClick={(e) => {
                                        // 1. Find the todo with the provided id
                                        const currentTodoIndex =
                                            operationValues.findIndex(
                                                (todo) =>
                                                    todo.operationID ===
                                                    item.operationID
                                            );

                                        // 2. Mark the todo as complete
                                        const updatedTodo = Object.assign(
                                            {},
                                            operationValues[currentTodoIndex]
                                        );
                                        updatedTodo.background = "success";

                                        // 3. Update the todo list with the updated todo
                                        const newTodos =
                                            operationValues.slice();
                                        newTodos[currentTodoIndex] =
                                            updatedTodo;
                                        setOrders(newTodos);
                                    }}
                                >
                                    <i className="fas fa-file-pdf"></i>
                                </a>
                            </>
                        ) : item.dailyRouteStatus == "06" ? (
                            <a
                                className="btn btn-warning ml-1"
                                href={`${hostname}/dispatches/print_ticket/${item.operationID}/`}
                                target="_blank"
                                title="Descargar PDF"
                                download
                            >
                                <i className="fas fa-file-pdf"></i>
                            </a>
                        ) : item.dailyRouteStatus == "07" ? (
                            <>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    title="Poner a pendiente"
                                    onClick={(e) => {
                                        putPresaleInPending(item).then(() => {
                                            toast.success(
                                                "Entrega puesta en pendiente con exito! "
                                            );
                                            document
                                                .getElementById("btn-filter")
                                                .click();
                                        });
                                    }}
                                >
                                    <i className="fas fa-hands-wash"></i>
                                </button>
                                <button
                                    type="button"
                                    className="btn bg-teal"
                                    title="Entregar Conforme"
                                    onClick={(e) => {
                                        sendPresaleDelivered(item).then(() => {
                                            toast.success(
                                                "Entrega realizada con exito! "
                                            );
                                            document
                                                .getElementById("btn-filter")
                                                .click();
                                        });
                                    }}
                                >
                                    <i className="fas fa-handshake"></i>
                                </button>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </td>
            </tr>
        );
    });

    return (
        <>
            <div className="table-responsive">
                <table
                    className="table table-sm table-bordered text-center mt-3"
                    id="table-to-xls"
                >
                    <thead>
                        <tr className="text-uppercase">
                            <td colSpan={2} className="align-middle bg-navy">
                                Preventa
                            </td>
                            <td colSpan={2} className="align-middle bg-navy">
                                Despacho
                            </td>
                            <td
                                colSpan={11}
                                className="align-middle bg-navy"
                            ></td>
                        </tr>
                        <tr className="text-uppercase">
                            <td className="align-middle bg-navy">Fecha</td>
                            <td className="align-middle bg-navy">Hora</td>
                            <td className="align-middle bg-navy">Fecha</td>

                            <td className="align-middle bg-navy">Hora</td>
                            <td className="align-middle bg-navy">Usuario</td>
                            <td className="align-middle bg-navy">Estado</td>

                            <td className="align-middle bg-navy">Codigo</td>

                            <td className="align-middle bg-navy">Cliente</td>

                            <td className="align-middle bg-navy">SIS. DIST</td>

                            <td className="align-middle bg-navy">Dia</td>
                            <td className="align-middle bg-navy">Direccion</td>

                            <td className="align-middle bg-navy">Serie</td>
                            <td className="align-middle bg-navy">
                                Correlativo
                            </td>
                            <td
                                className="align-middle bg-navy"
                                style={{ width: "8%" }}
                            >
                                Total
                            </td>
                            <td className="align-middle bg-navy">Acciones</td>
                        </tr>
                    </thead>
                    <tbody>{tbodies}</tbody>

                    <tfoot>
                        <tr>
                            <td
                                className="text-right font-weight-bold"
                                colSpan={13}
                            >
                                TOTAL
                            </td>
                            <td className="text-right font-weight-light">
                                S/{" "}
                                {Number(
                                    orders.reduce(
                                        (previousValue, currentValue) =>
                                            previousValue + currentValue.total,
                                        0
                                    )
                                ).toFixed(2)}
                            </td>
                            <td className="text-right font-weight-bold"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-outline-secondary mt-3 ml-1"
                table="table-to-xls"
                filename={filename}
                sheet="report"
                buttonText="Descargar como XLS"
            />
            <DetailList items={items} />
        </>
    );
}
